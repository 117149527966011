
import { useEffect, useRef, useState } from 'react';
import { FormikModelDialog, SubmitStatus } from '../../../components/formik-model-dialog.component';
import * as Yup from 'yup';
import { ClaimObservation } from '../../../models/claim/claim-observation';
import { Field } from 'formik';
import { ClaimObservationOption } from '../../../models/claim/claim-observation-option';
import { ClaimObservationOptionNote } from '../../../models/claim/claim-observation-note';
import { InputTextArea } from '../../../components/form/input-textarea.component';
import { usePostData } from '../../../hooks/use-post-data.hook';
import ConfirmDialog from '../../../components/confirm.dialog';

interface IEntityNoteProps {
    claimObservationNote: ClaimObservationOptionNote;
    onClose?: (status: SubmitStatus, data: any) => any;
}

export const EntityNote: React.FunctionComponent<IEntityNoteProps> = (props) => {

    const { claimObservationNote, onClose } = props;
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [initialData, setInitialData] = useState<ClaimObservationOptionNote>(claimObservationNote);
    const title = claimObservationNote && claimObservationNote?.note !== "" ? "Edit  Note" : "Add Note";
    const {
        submitData,
        response: { isSubmitting, result: resposeResult, error, submitActionType },
    } = usePostData<any>("ClaimObservation/SaveClaimObservationOptionNote", "POST");

    useEffect(() => {
        console.log(claimObservationNote)

    }, [claimObservationNote]);
    const validationSchema = Yup.object().shape({
        note: Yup.string().required('Note cannot be empty!!')
            .max(500, 'Max length must be 500 character')
    });


    const onConfirmDelete = async (submitStatus: SubmitStatus) => {
        setShowDeleteDialog(false);
        if (submitStatus == SubmitStatus.Delete) {
            await handleClickClear;
        } else {
            onClose(SubmitStatus.Completed, claimObservationNote);
        }
    }
    const handleClickClear = async () => {

        if (claimObservationNote?.note) {
            claimObservationNote.note = null;//Clear note
            await submitData({ data: claimObservationNote }).then((value: any) => {
                onClose(SubmitStatus.Completed, claimObservationNote);

            });
        }
    }
    const showDialog = (e: Event) => {
        setShowDeleteDialog(true);
    }
    return (
        <>
            {showDeleteDialog &&
                <ConfirmDialog title={"Delete Action"} onComfirm={onConfirmDelete} content={"Are you sure, Do you want to clear note?"}></ConfirmDialog>
            }
            <FormikModelDialog
                width='40%'
                title={title}
                show={!!claimObservationNote}
                initialData={initialData}
                validationSchema={validationSchema}
                //onCustomClick={handleClickClear}
                //showDefaultCustomButton={true}
                //defaultButtonText={"Clear Note"}
                // customButtons={<button id={"custom"} onClick={(e) => { showDialog }} className="btn btn-outline-secondary btn-sm">Clear Note</button>}
                postRoute="ClaimObservation/SaveClaimObservationOptionNote"
                onClose={onClose}
            >
                <>
                    <div className="col-12 justify-content-end">
                        {/* //<Field label={"Note"} as={"textarea"} name={"note"} length={10} defaultValue={claimObservationNote?.note} className="col-12" rows={3} cols={40} ></Field> */}
                        <InputTextArea label={"Note"} name={"note"}></InputTextArea>
                    </div>
                </>
            </FormikModelDialog ></>)

}