import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Formik } from 'formik';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { usePostData } from '../hooks/use-post-data.hook';
import {
    FormikBttonsComponent,
    SubmitActionType,
    SUBMIT_ACTION_TYPE_KEY_NAME,
} from './formik-buttons.component';
import { LoadingIndicator } from './loading-indicator.component';
import { updateEntityDetails } from '../utils/updateutil';

interface FormikModelDialogProps {
    children: ReactNode;
    customButtons?: ReactElement<any>;
    show: boolean;
    title: string | ReactElement<any>;
    footer?: ReactElement<any>;
    initialData: any;
    validate?: any;
    validationSchema?: any;
    postRoute: string;
    onClose?: (status: SubmitStatus, data: any) => any;
    postDataFormatter?: (data: any) => any;
    showAddAnother?: boolean;
    width?: string;
    isUpDateEntityDetails?: boolean;
    showDefaultCustomButton?: boolean;
    defaultButtonText?: string;
    onCustomClick?: (status: SubmitStatus, data: any) => any;
}

export enum SubmitStatus {
    Completed,
    CompletedAddNew,
    Cancel,
    Delete,
}

export const FormikModelDialog = ({
    children,
    customButtons,
    title,
    footer,
    show,
    initialData,
    isUpDateEntityDetails = false,
    width = "95%",
    validate,
    validationSchema,
    postRoute,
    onClose,
    postDataFormatter,
    showAddAnother,
    defaultButtonText,
    showDefaultCustomButton,
    onCustomClick,
}: FormikModelDialogProps) => {
    const {
        submitData,
        response: { isSubmitting, result, error, submitActionType },
    } = usePostData<any>(postRoute, 'POST');

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formDirty, setFormDirty] = useState(false);

    useEffect(() => {
        if (!isSubmitting && result && !error) {
            if (submitActionType === SubmitActionType.SAVE_AND_CLOSE) {
                onClose(SubmitStatus.Completed, result);
            }
            else {
                onClose(SubmitStatus.CompletedAddNew, result);
            }
        }
    }, [result, isSubmitting, initialData]);

    const handleSubmit = async (updatedData: any, button?: string) => {
        const submitActionType = updatedData[SUBMIT_ACTION_TYPE_KEY_NAME];
        delete updatedData[SUBMIT_ACTION_TYPE_KEY_NAME];
        const postData = postDataFormatter ? postDataFormatter(updatedData) : { ...updatedData };

        if (isUpDateEntityDetails) {
            await updateEntityDetails({ FloorID: updatedData.floorID, UnitID: updatedData.unitID, BuildingID: updatedData.buildingID, RoomID: updatedData.roomID }).then(async (res) => {
                return await submitData({ data: updatedData, submitActionType });
            });
        }
        else {
            return submitData({ data: postData, submitActionType });
        }
    };

    const handleClose = (event: any) => {

        if (event.preventDefault === undefined) {
            if (formDirty) {

                setShowConfirmation(true);
            } else {

                onClose(SubmitStatus.Cancel, null);
            }
        }
        else {
            event.preventDefault();
            onClose(SubmitStatus.Cancel, null);
        }
    };

    const handleCustom = (event: any) => {
        event.preventDefault();
        alert('sd')
        onCustomClick(SubmitStatus.Delete, null);
    };


    const handleConfirmationClose = (confirm: boolean) => {

        setShowConfirmation(false);
        if (confirm) {
            onClose(SubmitStatus.Cancel, null);
        }
    };

    return (
        <>
            {show && (
                <Dialog title={title} onClose={(e: any) => handleClose(e)} width={width} style={{ zIndex: 100 }}>
                    <div>
                        <Formik
                            validateOnMount
                            initialValues={initialData}
                            validate={validate}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                handleSubmit(values);
                            }}
                            enableReinitialize
                        >
                            {({ dirty, handleSubmit }) => {
                                setFormDirty(dirty);
                                return (
                                    <>
                                        <Form
                                            onSubmit={(event) => {
                                                event.preventDefault();
                                                handleSubmit();
                                            }}
                                        >
                                            <LoadingIndicator isLoading={isSubmitting} />
                                            {error && <div>Error</div>}
                                            {children}
                                            <DialogActionsBar>
                                                <FormikBttonsComponent
                                                    customButtons={customButtons}
                                                    showAddAnother={showAddAnother}
                                                    showDefaultCustomButton={showDefaultCustomButton}
                                                    defaultButtonText={defaultButtonText}
                                                    onCustomClick={(e: any) => handleCustom(e)}
                                                    onClose={(e: any) => handleClose(e)}
                                                />
                                            </DialogActionsBar>
                                        </Form>
                                    </>
                                );
                            }}
                        </Formik>
                        {footer}
                    </div>
                </Dialog>
            )}

            {showConfirmation && (
                <Dialog title="Alert Changes" className="delete-dialog" onClose={() => handleConfirmationClose(false)}>
                    <p>Are you sure you want to cancel the changes?</p>
                    <DialogActionsBar>
                        <div className="d-flex margin-fix p-3">
                            <button
                                className="btn btn-outline-secondary btn-sm"
                                onClick={() => handleConfirmationClose(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary ms-auto btn-sm"
                                onClick={() => handleConfirmationClose(true)}
                            >
                                Ok
                            </button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}
        </>
    );
};
