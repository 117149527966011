import { useEffect, useState } from "react";
import { ClaimObservationMain } from "../../../models/claim/claim-observation";
import { ObservationType } from "../../../models/masters/observation-type";
import { usePostData } from "../../../hooks/use-post-data.hook";
import EditIcon from "../../../images/edit";
import CameraIcon from "../../../images/camera-icon";
import GalleryIcon from "../../../images/gallery";
import { LoadingIndicator } from "../../../components/loading-indicator.component";
import { LoadingIndicatorDialog } from "../../../components/loading-indicator.component dialog";
import { useLoadData } from "../../../hooks/use-load-data.hook";

interface IEntityObservationHeaderProps {
    roomID: number;
    entityID: number;
    entityType: ObservationType;
    entityName?: string;
    claimObservationMain?: ClaimObservationMain;
}

export const EntityHeader: React.FunctionComponent<IEntityObservationHeaderProps> = (props) => {
    const { entityID, entityType, entityName, roomID } = props;


    const { isLoading, result, isError, reload } = useLoadData<ClaimObservationMain>(`ClaimObservation/GetClaimObservationMain?entityID=${entityID}&entityType=${entityType}`);
    const [claimObservationMainData, setClaimObservationMainData] = useState<ClaimObservationMain>(null)

    const {
        submitData: claimMainSubmit,
        response: { isSubmitting: isClaimMainSubmit, result: responseClaimMain, error: errorClaimMain, submitActionType: claimSubmitType },
    } = usePostData<any>('ClaimObservation/SaveClaimObservationMain', 'POST');

    useEffect(() => {
        console.log(result)
        if (result) {
            setClaimObservationMainData(result)
        }

    }, [claimObservationMainData, result]);

    const handleCheckChangeClaimMain = async (e: any, type: string) => {
        e.persist()
        const isEventRelated = claimObservationMainData?.isEventRelated;
        const isFlaged = claimObservationMainData?.isFlaged;
        const isCompliant = claimObservationMainData?.isCompliant;

        const claimObsMain: ClaimObservationMain = {
            claimObservationMainID: result?.claimObservationMainID,
            roomID: roomID,
            entityID: entityID,
            entityType: entityType,
            entityName: entityName,
            isEventRelated: e.target?.name === "eventrelated" ? (type === "YES" ? true : false) : isEventRelated,
            isFlaged: e.target?.name === "flaged" ? (type === "YES" ? true : false) : isFlaged,
            isCompliant: e.target?.name === "compliant" ? (type === "YES" ? true : false) : isCompliant,
            createdBy: 1
        }
        await claimMainSubmit({ data: claimObsMain }).then((value: any) => {
            if (value !== undefined) {
                setClaimObservationMainData(value);
                reload()
            }
        });

    }
    return (
        <>
            <div>
                <LoadingIndicatorDialog message={"Updating.."} isLoading={isClaimMainSubmit} ></LoadingIndicatorDialog>
            </div>
            <div className="d-flex justify-between mt-3">
                <div className="col-3 ps-3">{entityName}</div>
                {/* <div className="col d-flex justify-content-end" ><div style={{ cursor: "pointer" }}><CameraIcon></CameraIcon></div><div style={{ cursor: "pointer" }}><GalleryIcon></GalleryIcon></div></div> */}
                <div className='col'>
                    <div style={{ borderLeft: "1px solid #ddd", height: "100%" }}></div>
                </div>
                <div className="col">
                    <div style={{ display: "flex", marginInlineEnd: "10px", width: "400px", alignItems: 'center' }}>
                        <div>Compliant:</div>
                        {claimObservationMainData && claimObservationMainData?.isCompliant !== undefined ? (<>
                            <div className="d-flex align-items-center me-2">
                                <input name="compliant" checked={claimObservationMainData?.isCompliant === true} onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>Yes
                            </div >
                            <div className="d-flex align-items-center">
                                <input name="compliant" checked={!claimObservationMainData.isCompliant} onChange={(e) => { handleCheckChangeClaimMain(e, "NO") }} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>No
                            </div>
                        </>) : (<>
                            <div className="d-flex align-items-center me-2">
                                <input name="compliant" onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>Yes
                            </div>
                            <div className="d-flex align-items-center">
                                <input name="compliant" onChange={(e) => { handleCheckChangeClaimMain(e, "NO") }} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>No</div>
                        </>)}
                    </div>
                </div>
                <div className='col'>
                    <div style={{ borderLeft: "1px solid #ddd", height: "100%" }}></div>
                </div>
                <div className="col">
                    <div style={{ display: "flex", textAlign: 'center', width: "300px", marginInlineEnd: "20px" }}>
                        <div>Event Related:</div>
                        {claimObservationMainData && claimObservationMainData?.isEventRelated !== undefined ? (<>
                            <div className="d-flex align-items-center me-2">
                                <input name='eventrelated' checked={claimObservationMainData?.isEventRelated !== null ? claimObservationMainData?.isEventRelated : null} onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>Yes
                            </div>
                            <div className="d-flex align-items-center "><input name='eventrelated' checked={claimObservationMainData?.isEventRelated !== null ? !claimObservationMainData?.isEventRelated : null} onChange={(e) => { handleCheckChangeClaimMain(e, "NO") }} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>No</div>
                        </>) : (
                            <>
                                <div className="d-flex align-items-center me-2">
                                    <input name='eventrelated' onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>Yes
                                </div>
                                <div className="d-flex align-items-center me-2"><input name='eventrelated' onChange={(e) => { handleCheckChangeClaimMain(e, "NO") }} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>No</div>
                            </>
                        )}
                    </div>
                </div>
                <div className='col' style={{ display: "none" }}>
                    <div style={{ borderLeft: "1px solid #ddd", height: "100%" }}></div>
                </div>
                <div className="col" style={{ display: "none" }}>
                    <div className="d-flex" style={{ display: "flaged", width: "100px" }}>
                        <div>Flag:</div>
                        {claimObservationMainData?.isFlaged !== null ? (<>
                            <div>
                                <input name="flaged" checked={claimObservationMainData?.isFlaged !== null ? claimObservationMainData?.isFlaged : null} onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>
                            </div>
                        </>) : (<>
                            <div>
                                <input name="flaged" onChange={(e) => { handleCheckChangeClaimMain(e, "YES") }} style={{ marginRight: "5px", marginLeft: "10px" }} type='radio'></input>
                            </div>
                        </>)}
                    </div>
                </div>

            </div >
        </>

    );
};
