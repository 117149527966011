import React, { useRef, useState } from "react";
import { useLoadData } from "../../../hooks/use-load-data.hook";
import { Observation } from "../../../models/masters/observation";
import { ClaimObservation, ClaimObservationMain, Photo } from "../../../models/claim/claim-observation";
import { PanelBar, PanelBarItem, PanelBarItemProps, PanelBarSelectEventArguments } from "@progress/kendo-react-layout";
import { ObservationOption, OptionClaimValue, Photos } from "../../../models/masters/observation-option";
import { title } from "process";
import { InputField } from "../../../components/form/input.feild.component";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import ImageUploader from "../../../components/add-images.component";
import { UploadedFile } from "../../../redux/reducers/filereducer";
// import { deleteFile, fetchFile, ImgFile, uploadImages } from "../../../utils/uploadfile.util";
import {
    ImgFile,
    deleteFile,
    editComments,
    fetchFile,
    uploadImages,
} from '../../../utils/uploadfile.util';
import { IEntityList } from "../../../models/entity-list/entity-list";
import { ObservationType } from "../../../models/masters/observation-type";
import { fetchFiles } from "../../../api-client.ts/fileapi";
import { useDispatch } from 'react-redux';
import { AppDispatch } from "../../../store";
import FileDialogButton from "../../../components/add-image.button.component";
import { identity, sample } from "lodash";
import { DisplayImages } from "../../../components/display-images";
import { usePostData } from "../../../hooks/use-post-data.hook";
import { ClaimObservationOption } from "../../../models/claim/claim-observation-option";
import { Button, Tooltip } from "react-bootstrap";
import { EntityNote } from "./entity-note-component";
import { SubmitStatus } from "../../../components/formik-model-dialog.component";
import { Building } from "../../../models/claim/building";
import { ClaimObservationOptionNote } from "../../../models/claim/claim-observation-note";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { LoadingIndicatorDialog } from "../../../components/loading-indicator.component dialog";
import { setFlagsFromString } from "v8";
interface IEntityObservationProps {
    roomID: number;
    type: string;
    entityID: number;
    entityType: ObservationType;
    entityName?: string;
    optionId?: number;
    assetType?: string;
    building: Building;
}
interface IOptionValue {
    id: number,
    title: string
};

const EntityObervationList: React.FunctionComponent<IEntityObservationProps> = (props) => {

    const [isSubmit, setSubmit] = useState<boolean>(false);
    const [imgList, setImgList] = React.useState<ImgFile[]>([]);
    const { entityID, entityType, type, entityName, roomID, building } = props
    const observationOptionId = useRef<number>(0);
    const { isLoading, result, isError, reload } = useLoadData<Observation[]>(`Observation/GetObservationsByTypeAndEntityID?entityID=${props?.entityID}&&type=${props?.type}`);
    const [observations, setObservations] = useState<Observation[]>([]);
    const [obserOptions, setObserOptions] = useState<any>(null);
    const [optionPanel, setOptionPanel] = React.useState<PanelBarItemProps[]>([])
    const uploadedfileList = React.useRef<UploadedFile[]>([]);
    const [textColor, setTextColor] = useState<string>('black');
    const dispatch = useDispatch<AppDispatch>();
    const reSelectedObservation = useRef<Observation>(null);
    const refSelectedObservationOption = useRef<ObservationOption>(null)
    const [editNote, setEditNote] = useState<ClaimObservationOptionNote>(null);
    const textAreaValue = useRef<string>();
    const refClaimObservationID = useRef<number>(0);
    const [reloadDone, setIsReloadDone] = useState<boolean>(false);
    const [collapseOptions, setCollapseOptions] = useState<boolean>(false);


    const [showHideStorm, setShowHideStorm] = useState<boolean>(false)
    const refImageLoaded = useRef<number>(0);

    const [imgpreview, showImgPreview] = React.useState<{
        imgpreview: boolean;
        src: File;
        comment?: string;
    }>({
        imgpreview: false,
        src: null,
        comment: '',
    });
    const {
        submitData,
        response: { isSubmitting, result: resposeResult, error, submitActionType },
    } = usePostData<any>('ClaimObservation', 'POST');

    const {
        submitData: claimMainSubmit,
        response: { isSubmitting: isClaimMainSubmit, result: responseClaimMain, error: errorClaimMain, submitActionType: claimSubmitType },
    } = usePostData<any>('ClaimObservation', 'POST');


    React.useEffect(() => {
        if (result) {
            result?.map((item: Observation) => {
                item?.options?.map((optionItem: ObservationOption) => {
                    if (optionItem?.optionValue !== "") {
                        item.isObservatiobValueAvailable = true;
                    }
                    if (optionItem?.note !== "") {
                        item.isObservatiobValueAvailable = true;
                    }
                })
            })

            setObservations(result);
            if (reloadDone) {
                const selected = result?.find((p) => p.observationID == reSelectedObservation.current.observationID); // (reSelectedObservation.current.observationListID    
                if (selected) {
                    setIsReloadDone(false);
                    onSelect(selected);
                }
            }

        }
    }, [result, observations]);


    const handleAddFileToImgListForObservation = async (file: File) => {
        await uploadImages([file], {
            claimGuID: building.claimGuid,
            EntityID: entityID.toString(), // observations[0]?.observationID?.toString(),
            EntityType: entityType, // observations[0]?.observationType?.toString(),
            IsInterior: false,
            Comments: imgpreview.comment.toString(),
            OptionId: observationOptionId.current
        }).then((value: UploadedFile) => {
            loadImages();
            imgList.push({
                comment: imgpreview.comment!,
                file: file,
                isbaseImg: value.isBase,
                baseID: 0,
                entityID: value.entityID,
                entityType: value.entityType,
                fileID: value.fileID.toString(),
                userGuid: '',
                optionId: observationOptionId.current
            });
            showImgPreview({
                imgpreview: false,
                src: null,
            });
        });
    };
    const loadImages = async () => {
        try {
            //setPhoto([]);
            const action = await dispatch(
                fetchFiles({
                    FileType: 'image',
                    UserID: 0,
                    EntityType: entityType,
                    EntityID: entityID, //observations[0]?.observationID,
                    OptionId: observationOptionId?.current
                }),
            );

            if (action.payload) {
                let uploadedFileArray = action.payload as UploadedFile[];

                uploadedfileList.current = uploadedFileArray;

                let files = await Promise.all(
                    uploadedFileArray.map(async (e) => {
                        return fetchFile(
                            e.fileCDN,
                            e.comments,
                            e.entityID.toString(),
                            entityType,
                            e.isBasePhoto,
                            e.fileID.toString(),
                            e.basePhotoID
                        );
                    }),
                );
                setImgList(files);
            } else {
                setImgList([]);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setImgList([]);
        }
    };

    const handleOtherChange = (e: any) => {
        textAreaValue.current = e.target.value;
    }

    const handleOtherSave = async (e: any) => {
        if (textAreaValue !== undefined && textAreaValue?.current !== "") {
            if (reSelectedObservation.current !== undefined) {
                let claimObservationNote: ClaimObservation = {
                    observation: reSelectedObservation.current?.observationName,
                    entityID: entityID, //selected asset id
                    entityType: entityType,
                    observationListID: reSelectedObservation.current?.observationID,
                    observationID: refClaimObservationID.current,
                    isInterior: false,
                    note: "",
                    options: [],
                    isStormRelated: false,
                    photos: [],
                    images: 0,
                    perils: "",
                };

                if (claimObservationNote != null) {
                    const optionValue = { "optionId": observationOptionId.current, "option": "other", "value": textAreaValue.current }
                    const optionArray = [];
                    optionArray.push(optionValue);
                    const option: ClaimObservationOption = {
                        observationID: refClaimObservationID.current,
                        observationOption: "other",
                        observationOptionID: observationOptionId.current,
                        optionValue: JSON.stringify(optionArray),
                    }
                    claimObservationNote.options.push(option);
                    setSubmit(true);
                    await submitData({ data: claimObservationNote }).then((value: any) => {
                        setSubmit(false);
                        setIsReloadDone(true);
                        reload();
                    });
                }
            }
        }
    }

    const handleCheckChange = async (e: any, type: string, item: ObservationOption, itemIndex?: number) => {

        event.preventDefault();
        if (refSelectedObservationOption.current === null) {
            refSelectedObservationOption.current = item
        }
        if (reSelectedObservation.current !== undefined) {
            let claimObservation: ClaimObservation = {
                observation: reSelectedObservation.current?.observationName,
                entityID: entityID, //selected asset id
                entityType: entityType,
                observationListID: reSelectedObservation.current?.observationID,
                observationID: refClaimObservationID.current,
                isInterior: false,
                note: "",
                options: [],
                isStormRelated: false,
                photos: [],
                images: 0,
                perils: "",
            };
            const optionValue = { "optionId": observationOptionId.current, "option": e.target?.name, "value": type === "YES" ? 1 : 0 }
            const arrOptions = [];
            arrOptions.push(optionValue)
            const option: ClaimObservationOption = {
                observationID: refClaimObservationID.current,
                observationOption: e.target?.name,
                observationOptionID: e.target?.id,
                optionValue: JSON.stringify(arrOptions),
                note: item?.note
            }
            claimObservation.options.push(option);

            await submitData({ data: claimObservation }).then((value: any) => {

                if (value) {
                    refClaimObservationID.current = value?.observationID;
                }
                setIsReloadDone(true);
                reload();

            });
        }
    };

    //Dynamic Item Check Selection
    const handlOptionItemCheckChange = async (e: any, item: IOptionValue, isMandatory: boolean = false, itemObservationOption: ObservationOption,) => {
        e.persist()
        if (refSelectedObservationOption.current === null) {
            refSelectedObservationOption.current = itemObservationOption
        }
        if (reSelectedObservation.current !== undefined) {
            let claimObservation: ClaimObservation = {
                observation: reSelectedObservation.current?.observationName,
                entityID: entityID, //selected asset id
                entityType: entityType,
                observationListID: reSelectedObservation.current?.observationID,
                observationID: refClaimObservationID.current,
                isInterior: false,
                note: "",
                options: [],
                isStormRelated: false,
                photos: [],
                images: 0,
                perils: "",
            };


            const optionValue1 = {
                "optionId": refSelectedObservationOption.current.observationOptionID, "option": e?.target?.name, "value": item?.title
            }

            let optionValueString = JSON.stringify(optionValue1)

            if (isMandatory) {
                const optionValueList = [];
                const optionValue = {
                    "optionId": refSelectedObservationOption.current.observationOptionID, "option": refSelectedObservationOption.current?.optionValueArray[0]?.option, "value": refSelectedObservationOption.current?.optionValueArray[0]?.value
                }
                optionValueList.push(optionValue);
                optionValueList.push(optionValue1);
                optionValueString = JSON.stringify(optionValueList)

            }

            const option: ClaimObservationOption = {
                observationID: refClaimObservationID.current,
                observationOption: e.target?.name,
                observationOptionID: e.target?.id,
                optionValue: optionValueString,
                note: refSelectedObservationOption?.current?.note
            }
            claimObservation.options.push(option);
            await submitData({ data: claimObservation }).then((value: any) => {
                if (value) {
                    refClaimObservationID.current = value?.observationID;
                }
                setIsReloadDone(true);
                reload();
            });

        }
    };
    const handleSaveNote = (observationOption: ObservationOption) => {
        setEditNote(null);
        if (reSelectedObservation.current !== undefined) {

            const selectedNote: ClaimObservationOptionNote = {
                entityID: entityID,
                entityType: entityType,
                observation: reSelectedObservation.current?.observationName,
                observationListID: reSelectedObservation.current?.observationID,
                observationID: reSelectedObservation.current?.claimObservationID,
                observationOption: observationOption?.optionName,
                observationOptionID: observationOption?.observationOptionID,
                optionValue: observationOption?.optionValue,
                note: observationOption?.note,
            }
            setEditNote(selectedNote);
        }
    }

    const handleOptionClick = (item: ObservationOption) => {
        refSelectedObservationOption.current = item;
        observationOptionId.current = item?.observationOptionID;
        if (refImageLoaded.current !== item?.observationOptionID) {
            loadImages();
            refImageLoaded.current = item?.observationOptionID;
            console.log(refImageLoaded.current)
        }


    }
    const handleSaveNoteComplete = (status: SubmitStatus, data: ClaimObservation) => {
        if (status === SubmitStatus.Completed) {
            reload();
            setIsReloadDone(true)
        }
        setEditNote(null)
    };


    const onSelect = async (observation: Observation) => {

        observation?.options?.map((p) => {
            p.optionItemsArray = null;

            p.photos === null ? [] : p.photos;
            if (p?.optionItems && p?.optionItems !== "") {
                p.optionItemsArray = JSON.parse(p?.optionItems);
            }
            if (p?.optionValue && p?.optionValue !== "") {
                p.optionValueArray = [];
                const parsedValue = JSON.parse(p?.optionValue);
                if (!Array.isArray(parsedValue) && parsedValue != undefined) {
                    p.optionValueArray.push(parsedValue);
                } else {
                    p.optionValueArray = parsedValue;
                }
            }
            if (entityType === "window") {
                if (p?.optionName === "Moisture Intrusion" && p?.optionValueArray && p?.optionValueArray[0]?.value == 1) {
                    setShowHideStorm(true);
                } else {
                    setShowHideStorm(false);
                }
            }
        });

        if (observation?.options?.length > 0) {
            refSelectedObservationOption.current = observation?.options[0];
        }
        reSelectedObservation.current = observation;
        refClaimObservationID.current = observation.claimObservationID;
        setImgList([]);
        const optionList = await observation?.options && observation?.options?.length > 0
            && observation?.options?.map((item: ObservationOption, index): PanelBarItemProps => ({
                id: item?.observationOptionID.toString(),
                className: item?.optionName,
                title: <div onClick={() => { handleOptionClick(item) }} style={{ fontWeight: "bold", cursor: "pointer" }}> {item?.optionName}</div >,
                children: [
                    <div>
                        <div className="row">
                            {!item?.isMandatory && item?.optionItemsArray && item?.optionItemsArray?.length > 0 ?
                                (<div className="d-flex m-3 justify-between-center">
                                    {item?.optionItemsArray && item?.optionItemsArray?.map((optionItem: IOptionValue) => (
                                        <div className="mb-2">
                                            <input checked={item?.optionValueArray && item?.optionValueArray?.length > 0 && item?.optionValueArray[0]?.value == optionItem?.title ? item?.optionValueArray[0]?.value : null} id={item?.observationOptionID.toString()} onChange={(e) => { handlOptionItemCheckChange(e, optionItem, false, item) }} name={item?.optionName} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>{optionItem?.title}
                                        </div>
                                    ))}
                                </div>) :
                                (<>
                                    <div className="d-flex justify-between-center" style={{ minHeight: "20%", padding: "10px" }}>
                                        <div style={{ cursor: "pointer", width: "auto", marginInlineStart: "20px", marginInlineEnd: "20px" }}>
                                            {item?.optionName?.toLowerCase() !== "other" ? (<>
                                                <div className="d-flex">
                                                    {item?.optionTitle && item?.optionTitle != "" ? <div>{item?.optionTitle}</div> : ""}
                                                    {item?.optionValueArray ? (<div>
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center"><input id={item?.observationOptionID.toString()} checked={item?.optionValueArray && item?.optionValueArray?.length > 0 && item?.optionValueArray[0]?.value === 1} onChange={(e) => { handleCheckChange(e, "YES", item, index) }} name={item?.optionName} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>Yes</div>
                                                            <div className="d-flex align-items-center"><input id={item?.observationOptionID.toString()} checked={item?.optionValueArray && item?.optionValueArray?.length > 0 && item?.optionValueArray[0]?.value === 0} onChange={(e) => { handleCheckChange(e, "NO", item, index) }} name={item?.optionName} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>No</div>
                                                        </div>
                                                    </div>)
                                                        : (
                                                            <div className="d-flex">
                                                                <div className="d-flex align-items-center"><input id={item?.observationOptionID.toString()} onChange={(e) => { handleCheckChange(e, "YES", item, index) }} name={item?.optionName} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>Yes</div>
                                                                <div className="d-flex align-items-center"><input id={item?.observationOptionID.toString()} onChange={(e) => { handleCheckChange(e, "NO", item, index) }} name={item?.optionName} style={{ marginRight: "5px", marginLeft: "10px", cursor: "pointer" }} type='radio'></input>No</div>
                                                            </div>
                                                        )}
                                                </div>
                                                {item?.optionValueArray?.length > 0 && item?.optionValueArray[0]?.option === "Moisture Intrusion" ? (
                                                    <div style={{
                                                        display:
                                                            item?.optionValueArray?.length > 0 && item?.optionValueArray[0]?.value != undefined && item?.optionValueArray[0]?.value === 1 && item?.optionValueArray[0]?.option === "Moisture Intrusion" ?
                                                                "block" :
                                                                "none"
                                                    }} >
                                                        {item?.isMandatory && item?.optionItemsArray && item?.optionItemsArray?.map((optionItem: IOptionValue) => (
                                                            <div className="mt-3">
                                                                <input checked={item?.optionValueArray?.length === 2 && item?.optionValueArray[1]?.value == optionItem?.title ? item?.optionValueArray[1]?.value : null} id={item?.observationOptionID.toString()}
                                                                    onChange={(e) => { handlOptionItemCheckChange(e, optionItem, true, item) }} name={item?.optionName + "-option-" + item?.observationOptionID} style={{ marginRight: "5px", cursor: "pointer" }} type='radio'></input>{optionItem?.title}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div style={{
                                                        display:
                                                            item?.optionValueArray?.length > 0 && item?.optionValueArray[0]?.value != undefined && item?.optionValueArray[0]?.value === 0 && item?.optionValueArray[0]?.option === "Positive Slope" ?
                                                                "block" :
                                                                "none"
                                                    }} >
                                                        {item?.isMandatory && item?.optionItemsArray && item?.optionItemsArray?.map((optionItem: IOptionValue) => (
                                                            <div className="mt-3">
                                                                <input checked={item?.optionValueArray?.length === 2 && item?.optionValueArray[1]?.value == optionItem?.title ? item?.optionValueArray[1]?.value : null} id={item?.observationOptionID.toString()}
                                                                    onChange={(e) => { handlOptionItemCheckChange(e, optionItem, true, item) }} name={item?.optionName + "-option-" + item?.observationOptionID} style={{ marginRight: "5px", cursor: "pointer" }} type='radio'></input>{optionItem?.title}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>) : (<>
                                                <div className="col-12 justify-content-end">
                                                    <TextArea onChange={(e) => { handleOtherChange(e) }} defaultValue={item?.optionValueArray?.length > 0 && item?.optionValueArray[0]?.value} className="col-12" name={item?.optionName} rows={3} cols={40}></TextArea>
                                                    <Button style={{ float: "right" }} onClick={(e) => handleOtherSave(e)} className="mt-2 btn-sm btn-primary">
                                                        {item?.optionValueArray?.length > 0 && item?.optionValueArray[0]?.value !== undefined ? "Update" : "Save"}
                                                    </Button>
                                                </div>
                                            </>)}
                                        </div>
                                    </div >
                                </>)}
                        </div >
                        <hr style={{ margin: "1px" }}></hr>
                        <div className="row d-flex">
                            <div className="col-6 d-flex justify-content-start mt-1 mb-1">
                                <button
                                    className="add-edit-btn btn-sm "
                                    style={{ textDecoration: "none" }}
                                    onClick={() => { document.getElementById('fileInputObservation').click(); }}
                                >
                                    Add Photo
                                </button>
                            </div>
                            <div className="col-6 d-flex justify-content-end mt-1 mb-1">
                                {item?.optionName !== "Other" ?

                                    (<>{item?.note && item?.note !== "" ?
                                        (<>
                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props}>
                                                        {item?.note}
                                                    </Tooltip>
                                                )}
                                                placement="bottom">
                                                <button
                                                    className="add-edit-btn ms-auto"
                                                    style={{ textDecoration: "none" }}
                                                    onClick={() => { handleSaveNote(item) }}
                                                >
                                                    Edit Note
                                                </button>
                                            </OverlayTrigger></>) : (<>
                                                <button
                                                    className="add-edit-btn ms-auto"
                                                    style={{ textDecoration: "none" }}
                                                    onClick={() => { handleSaveNote(item) }}
                                                >
                                                    Add Note
                                                </button>
                                            </>)}</>) : ("")}
                            </div>
                        </div >
                    </div >
                ]
            }))
        if (optionList) {
            setOptionPanel(optionList)
            if (refSelectedObservationOption.current != null) {
                handleOptionClick(refSelectedObservationOption.current);
            }
        } else {
            setOptionPanel(null);
        }
    };



    return (<>

        <div className="col-4" style={{ overflow: "y", padding: "5px", borderRight: "1px solid gray", height: "100%" }}>
            {observations &&
                observations?.length > 0 &&
                observations?.map((item, index) => (
                    <div
                        style={textColor !== item?.observationName ? item?.isObservatiobValueAvailable && item?.isObservatiobValueAvailable == true ? { cursor: "pointer", fontWeight: "bold", color: "#000", padding: "10px" } : { cursor: "pointer", fontWeight: "bold", color: "grey", padding: "10px" } : { cursor: "pointer", fontWeight: "bold", color: "#f58a1f", padding: "10px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onSelect(item);
                            setTextColor(item?.observationName);
                        }}>
                        {item?.observationName}
                    </div>
                ))
            }
        </div >
        {editNote && <EntityNote claimObservationNote={editNote} onClose={handleSaveNoteComplete} ></EntityNote>}
        <div className="col-4">
            <div style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}>
                <div><LoadingIndicatorDialog isLoading={isSubmitting} message={"Updating.."} ></LoadingIndicatorDialog></div>
                {optionPanel && (<PanelBar expanded={[]} expandMode={"single"} >
                    {optionPanel.map((panelItem) => (
                        (<PanelBarItem title={panelItem.title}>
                            {panelItem.children}
                        </PanelBarItem>))
                    )}
                </PanelBar>)}
            </div>
        </div >
        <div className="col-4">
            <div style={{ height: "700px", borderLeft: "1 px solid gray" }}>
                <div className="photoGal-wrap">
                    <DisplayImages
                        handleCancel={async () => {
                            await loadImages();
                        }}
                        handleDelete={async (img: ImgFile) => {
                            const isdeleted: boolean = await deleteFile(img);
                            if (isdeleted) {
                                loadImages();
                            }
                        }}
                        handlesave={async (file: ImgFile) => {
                            //handleSave(img);
                            const isedited = await editComments({
                                fileId: file.fileID!,
                                comments: file.comment,
                                isBaseImg: file.isbaseImg,
                                baseID: file.baseID,
                                entityID: file.entityID,
                                entityType: file.entityType,
                                fileID: file.fileID,
                                userGuid: '',

                            });
                            if (isedited) {
                                loadImages();
                            }
                        }}
                        imgList={imgList ? imgList : []}
                    />
                </div>
            </div>
            <div className="mt-3 d-flex justify-content-end" style={{}}>
                {/* <FileDialogButton
                    fileInputID={"fileInputObservation"}
                    handleImgSelect={(e) => {
                        e.preventDefault();
                        //handleAddFileToImgList(e.target.files[0])
                        showImgPreview({
                            imgpreview: true,
                            src: e.target.files[0],
                        });
                    }}
                ></FileDialogButton>{' '} */}
                <ImageUploader

                    onAddImg={(comment: string) => {
                        imgpreview.comment = comment;
                        handleAddFileToImgListForObservation(imgpreview.src);
                    }}
                    onclose={() => {
                        showImgPreview({
                            imgpreview: false,
                            src: null,
                        });
                    }}
                    showModal={imgpreview.imgpreview}
                    img={imgpreview.src}
                />
            </div><div>
                <input
                    id={'fileInputObservation'}
                    type="file"
                    accept="image/jpeg, image/png"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        e.preventDefault();
                        const file = e.target.files[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = (event) => {
                                //  setSelectedImage(event.target.result);
                                //handleAddFileToImgListForObservation(event.target.result)
                            };
                            reader.readAsDataURL(file);
                        }
                        showImgPreview({
                            imgpreview: true,
                            src: e.target.files[0],
                        });

                    }}
                />
            </div>
            <div></div>
        </div >
    </>)
};
export default EntityObervationList
