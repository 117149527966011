import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { SubmitStatus } from "./formik-model-dialog.component";

interface IConfirmDialogProps {
    title: string;
    content: string;
    onComfirm: Function;
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({ ...props }) => {
    const { title, onComfirm, content } = props;

    const handleConfirmationClose = (confirm: boolean) => {
        if (confirm) {
            onComfirm(SubmitStatus.Completed);
        } else {
            onComfirm(SubmitStatus.Cancel);
        }
    };
    return (
        <>
            <Dialog title={title} className="delete-dialog" onClose={() => handleConfirmationClose(false)}>
                <p>{content}?</p>
                <DialogActionsBar>
                    <div className="d-flex margin-fix p-3">
                        <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => handleConfirmationClose(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary ms-auto btn-sm"
                            onClick={() => handleConfirmationClose(true)}
                        >
                            Ok
                        </button>
                    </div>
                </DialogActionsBar>
            </Dialog>

        </>
    );

};
export default ConfirmDialog