import * as React from 'react';
import { Breadcrumb, PanelBar, PanelBarItem, PanelBarItemProps } from '@progress/kendo-react-layout';

import { useLocation, useNavigate } from 'react-router-dom';
import { IEntityList } from '../../../models/entity-list/entity-list';
import { BreadcrumbDelimiter } from '@progress/kendo-react-layout';
import { UnitWindow } from '../../../models/claim/unit-window';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import ImageUploader from '../../../components/add-images.component';
import { GuardedComponent } from '../../../components/guarded.componemt';
import DeleteIcon from '../../../images/delete';
import EditIcon from '../../../images/edit';
import { EntityType } from '../../../models/claim/entity-type';
import { ObservationType } from '../../../models/masters/observation-type';
import { PermissionName } from '../../../models/user-security/permission.name';
import { ImgFile, deleteFile, editComments, fetchFile, uploadImages } from '../../../utils/uploadfile.util';
import { ObservationListComponent } from '../observation/observation-list.component';
import { useDispatch } from 'react-redux';
import { fetchFiles } from '../../../api-client.ts/fileapi';
import { DeleteModelDialog, DeletePostDataInfo, DeleteStatus } from '../../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../../components/formik-model-dialog.component';
import { Building } from '../../../models/claim/building';
import { ClaimObservation, ClaimObservationMain } from '../../../models/claim/claim-observation';
import { ClaimObservationOption } from '../../../models/claim/claim-observation-option';
import { UploadedFile } from '../../../redux/reducers/filereducer';
import { AppDispatch } from '../../../store';
import { deleteArrayItem, addOrUpdateArrayItem } from '../../../utils/array.util';
import { CheckButton, Image } from 'react-grid-gallery';
import { LinkImageModelDialog } from '../../building-image/link-image-model-dialog.component';
import { Input } from '@progress/kendo-react-inputs';
import { InputField } from '../../../components/form/input.feild.component';
import { InputFieldScheduler } from '../../../components/form/Inputfield-scheduler';
import { CheckboxField } from '../../../components/form/checkbox-field.component';
import EntityObervationList from './entity-observation';
import { RoomEntity } from '../../../models/claim/room-entity';
import './entity-list.style.scss'
import { EditWindow } from '../window/edit-window.component';
import { UnitRailing } from '../../../models/claim/unit-railing';
import { EditRailing } from '../railing/edit-railing.component';
import { UnitDoor } from '../../../models/claim/unit-door';
import { EditDoor } from '../door/edit-door.component';
import FileDialogButton from '../../../components/add-image.button.component';
import { DisplayImages } from '../../../components/display-images';
import { EntityHeader } from './entity-header';
import { Room } from '../../../models/claim/room';
import { Floor } from '../../../models/claim/floor';
import { EntityGallery } from './entity-gallery';
// import { CheckboxField } from '../../../components/form/checkbox-field.component';
interface IEntityListProps {
    room: Room;
    building: Building;
    floorName?: string;
    unitName?: string;
}

const EntityList: React.FunctionComponent<IEntityListProps> = (props) => {
    const entityList = props
    const { floorName, room, unitName, building } = entityList;
    const [entityPanel, setEntityPanel] = React.useState<PanelBarItemProps[]>([])
    const { isLoading, result, isError, reload } = useLoadData<RoomEntity[]>(`rooms/GetRoomEntitiesByRoomID?roomID=${room?.roomID}`);

    const [dataWindow, setEditWindow] = React.useState<{
        building: Building;
        window: UnitWindow;
        copyFrom: boolean;
    }>();
    const [dataRailing, setEditRailing] = React.useState<{ building: Building; editRailing: UnitRailing }>();
    const [dataDoor, setEditDoor] = React.useState<{ building: Building; editDoor: UnitDoor }>();


    const [newObservation, setNewObservation] = React.useState<{
        newObservation: ClaimObservation;
        copyFrom?: boolean;
        updateOption: ClaimObservationOption;
    }>();
    const [photos, setPhoto] = React.useState<Image[]>();
    const [roomData, setRoomData] = React.useState<RoomEntity[]>();
    const [deleteWinowData, setDeleteWindowData] = React.useState<DeletePostDataInfo>();
    const [deleteObservation, setDeleteObservation] = React.useState<DeletePostDataInfo>();
    const [imgList, setImgList] = React.useState<ImgFile[]>([]);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    React.useEffect(() => {

        if (result) {
            const roomData = { ...result };
            setRoomData(roomData);
        }
    }, [result]);

    const onAddWindowClick = (copyFrom: boolean) => {
        const selectedWindow: UnitWindow = {
            roomID: room.roomID,
            windowID: 0,
            windowName: '',
            direction: '',
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditWindow({ building: building, window: selectedWindow, copyFrom });
    };
    const onAddRailing = () => {
        const selectedRailing: UnitRailing = {
            roomID: room.roomID,
            railingID: 0,
            railingName: '',
            direction: '',
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditRailing({ building: null, editRailing: selectedRailing });
    };

    const onAddDoor = () => {
        const selectedDoor: UnitDoor = {
            roomID: room.roomID,
            doorID: 0,
            doorName: '',
            direction: '',
            notes: '',
            images: 0,
            attributes: [],
            observations: [],
        };
        setEditDoor({ building: null, editDoor: selectedDoor });
    };

    const onEditDoor = (selectedDoor: UnitDoor) => {
        setEditDoor({ building: null, editDoor: selectedDoor });
    };

    const onEditAssets = (selectedAsset: RoomEntity) => {
        if (selectedAsset != null) {
            if (selectedAsset?.entityType?.toLowerCase() === "window") {

                const selectedWindow: UnitWindow = {
                    roomID: room.roomID,
                    windowID: selectedAsset?.entityID,
                    windowName: selectedAsset?.entityName,
                    direction: selectedAsset?.direction,
                    notes: selectedAsset?.notes,
                    images: 0,
                    attributes: selectedAsset.attributes,
                    observations: [],
                    isActive: selectedAsset?.IsActive,
                    hasDamage: selectedAsset?.hasDamaged
                };
                setEditWindow({ building: building, window: selectedWindow, copyFrom: false });
            }
            else if (selectedAsset?.entityType?.toLowerCase() === "door") {
                const selectedDoor: UnitDoor = {
                    roomID: room.roomID,
                    doorID: selectedAsset?.entityID,
                    doorName: selectedAsset?.entityName,
                    direction: selectedAsset?.direction,
                    notes: selectedAsset?.notes,
                    images: 0,
                    attributes: selectedAsset.attributes,
                    observations: [],
                    isActive: selectedAsset?.IsActive,
                    hasDamage: selectedAsset?.hasDamaged
                };
                setEditDoor({ building: building, editDoor: selectedDoor });
            }
            else if (selectedAsset?.entityType?.toLowerCase() === "railing") {
                const selectedRailing: UnitRailing = {
                    roomID: room.roomID,
                    railingID: selectedAsset?.entityID,
                    railingName: selectedAsset?.entityName,
                    direction: selectedAsset?.direction,
                    notes: selectedAsset?.notes,
                    images: 0,
                    attributes: selectedAsset.attributes,
                    observations: [],
                    isActive: selectedAsset?.IsActive,
                    hasDamage: selectedAsset?.hasDamaged
                };
                setEditRailing({ building: building, editRailing: selectedRailing });
            }
        }
    }
    const onEditWindowClick = (selectedWindow: UnitWindow) => {
        setEditWindow({ building: building, window: selectedWindow, copyFrom: false });
    };
    const onDeleteWindow = (selectedWindow: UnitWindow) => {
        setDeleteWindowData({
            data: selectedWindow,
            route: `windows/delete/${selectedWindow.windowID}`,
        });
    };

    const handleDeleteWindowComplete = (status: DeleteStatus, data: RoomEntity) => {
        if (status == DeleteStatus.Completed) {
            const roomDatas = deleteArrayItem(roomData, data, 'entityId');
            setRoomData({ ...roomDatas });
            //setImgList([]);
            //setPhoto([]);
        }
        setDeleteWindowData(null);
    };

    const handleSaveWindowComplete = (status: SubmitStatus, data: UnitWindow) => {
        if (status === SubmitStatus.Completed) {
            // const window = roomData.windows.find((w) => w.windowID === data.windowID);
            // roomData.windows = addOrUpdateArrayItem(
            //     roomData.windows,
            //     { ...window, ...data },
            //     'windowID',
            // );
            reload();
            setRoomData({ ...roomData });
        }
        setEditWindow(null);
        //setPhoto([]);
        //setImgList([]);
        //loadImages();
    };

    const handleSaveRailingComplete = (status: SubmitStatus, data: UnitRailing) => {
        if (status === SubmitStatus.Completed) {
            reload()
            setRoomData({ ...roomData });
            setEditRailing(null);
            //setPhoto([]);
            //setImgList([]);
            //loadImages();
        } else {
            setEditRailing(null);
        }
    };

    // const handleLinkWinodwComplete = () => {
    //     setLinkImageData(null);
    // };
    const onEditRailing = (selectedRailing: UnitRailing) => {
        setEditRailing({ building: null, editRailing: selectedRailing });
    };
    const handleSaveDoorComplete = (status: SubmitStatus, data: UnitDoor) => {
        if (status === SubmitStatus.Completed) {
            reload(); setRoomData({ ...roomData });
            setEditDoor(null);
        } else {
            setEditDoor(null);
        }
    };
    const titleRow = <div className="d-flex justify-between ps-3" style={{ paddingRight: 42 }}>
        <h6 className="col " style={{ fontWeight: 'bold' }}>Name</h6>
        <h6 className="col" style={{ fontWeight: 'bold' }}>Asset</h6>
        <h6 className="col" style={{ fontWeight: 'bold' }}>Type</h6>
        <h6 className="col" style={{ fontWeight: 'bold' }}>Material</h6>
        <h6 className="col" style={{ fontWeight: 'bold' }}>Elevation</h6>
        <h6 className="col" style={{ fontWeight: 'bold' }}>Note</h6>
        <h6 className="col text-center" style={{ fontWeight: 'bold' }}>Action</h6>


    </div>


    React.useEffect(() => {
        if (result) {
            result?.map((item: RoomEntity) => {
                if (item?.claimObservationMain) {
                    console.log("CM:", item?.claimObservationMain)
                }

            })

            let entityPanel = result?.map((item: RoomEntity): PanelBarItemProps => ({
                id: item?.entityID.toString(),
                title: <div style={{ cursor: "pointer", padding: "5px" }}>
                    <div className="d-flex justify-between">
                        <div className="col">{item?.entityName}</div>
                        <div className="col">{item?.entityType}</div>
                        <div className="col">{item?.type}</div>
                        <div className="col">{item?.material}</div>
                        <div className="col">{item?.direction}</div>
                        <div className="col">{item?.notes}</div>
                        <div className="col text-center">{<div onClick={(e) => onEditAssets(item)}  ><EditIcon></EditIcon></div>}</div>
                    </div>
                </div>,
                children: [
                    <div className="me-2">
                        <EntityHeader
                            roomID={room?.roomID}
                            entityName={item?.entityName}
                            claimObservationMain={item?.claimObservationMain}
                            entityType={
                                item?.entityType?.toLowerCase() === "window" ?
                                    ObservationType.Window :
                                    item?.entityType?.toLowerCase() === "door" ?
                                        ObservationType.Door : ObservationType.Railing}
                            entityID={item?.entityID}
                        >
                        </EntityHeader>
                        <hr />
                        <div className='row'>
                            <div className='col-12 d-flex justify-between'>
                                <EntityObervationList
                                    roomID={room?.roomID}
                                    entityType={
                                        item?.entityType?.toLowerCase() === "window" ?
                                            ObservationType.Window :
                                            item?.entityType?.toLowerCase() === "door" ?
                                                ObservationType.Door : ObservationType.Railing}
                                    entityID={item?.entityID}
                                    building={props?.building}
                                    type={item?.entityType}>
                                </EntityObervationList>
                            </div>
                        </div>
                        <hr />
                        <EntityGallery
                            roomID={room?.roomID}
                            entityType={
                                item?.entityType?.toLowerCase() === "window" ?
                                    ObservationType.Window :
                                    item?.entityType?.toLowerCase() === "door" ?
                                        ObservationType.Door : ObservationType.Railing}
                            entityID={item?.entityID}
                            claimGuID={item?.type}

                        ></EntityGallery>
                    </div >,
                ],

            }))
            setEntityPanel(entityPanel);
        }
    }, [isLoading, result])

    return (<div className='ms-2 me-2 p-2'>


        <div className="d-flex justify-content-end mb-4">
            <div className="d-flex justify-content-end ">
                <div className="col-3 me-3">
                    <div onClick={() => onAddDoor()} style={{ width: "120px" }} className="btn btn-outline-primary btn-sm me-3">Add Door</div>
                </div>
                <div className="col-3 me-3">
                    <div onClick={() => onAddWindowClick(false)} style={{ width: "120px" }} className="btn btn-outline-primary btn-sm me-3">Add Window</div>
                </div>
                <div className="col-3">
                    <div onClick={() => onAddRailing()} style={{ width: "120px" }} className="btn btn-outline-primary btn-sm me-3">Add Railing</div>
                </div>



            </div>
        </div>

        <div >
            {titleRow}
        </div>
        <div className='row' >
            {isLoading && (<LoadingIndicator isLoading></LoadingIndicator>)}
            {
                result && (<PanelBar expandMode={"single"} className='k-back-color'   >
                    {entityPanel.map((panelItem) => (
                        panelItem ? (<PanelBarItem title={panelItem?.title} className="k-back-color" >
                            {panelItem.children}
                        </PanelBarItem>) : (null)
                    ))}
                </PanelBar>)
            }
        </div>
        {dataWindow && <EditWindow data={dataWindow} onClose={handleSaveWindowComplete} />}
        {dataRailing && <EditRailing data={dataRailing} onClose={handleSaveRailingComplete} />}
        {dataDoor && <EditDoor data={dataDoor} onClose={handleSaveDoorComplete} />}
    </div >);
};

export default EntityList;
